//----------------------
// Datatables
//----------------------
div.dataTables_wrapper {
  .row {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  div.dataTables_length {
    align-items: center;
    &.d-flex.flex-row {
      label {
        margin-top: 0 !important;
        margin: 0 0.75rem 0 0;
      }
      .select-wrapper.mdb-select {
        .select-dropdown {
          margin: 0;
        }
        span {
          margin-top: 0;
        }
      }
    }
  }

  .dropdown-content li > a,
  .dropdown-content li > span {
    color: $secondary-color;
  }

  div.dataTables_filter {
    margin: 0;
  }
  .md-form input[type="search"]:not(.browser-default) {
    margin-left: 0;
    margin-top: 0.75rem;
    margin-bottom: 0;
    width: 60%;
    @media (min-width: $medium-screen) {
      margin-top: 0;
      width: 100%;
      display: block;
    }
  }
}

table {
  &.dataTable thead .sorting_asc:before,
  &.dataTable thead .sorting_desc:after {
    color: $secondary-color;
  }
  &.table.btn-table td:last-child {
    text-align: right;
    .btn:last-child {
      margin-right: 0;
    }
  }

  &.table a {
    font-weight: bold;
    color: $primary-color;
    &:hover {
      color: darken($primary-color, 10%);
    }
  }

  .table-collapsed & td {
    padding: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .table-collapsed & td,
  .table-collapsed & th {
    font-size: 0.75rem;
    @media (min-width: $large-screen) {
      font-size: 0.8rem;
    }
    @media (min-width: $xlarge-screen) {
      font-size: 0.9rem;
    }
  }

  .table-row-links & tbody tr {
    cursor: pointer;
    td:last-child {
      opacity: 0;
      transition: 250ms opacity cubic-bezier(0.6, -0.28, 0.735, 0.045);
    }
    &:hover td:last-child {
      opacity: 1;
    }
  }
}

.contract-filter-fix-bottom {
  margin-bottom: 20px !important;
}
