$primary-color: #f88b5e;
$secondary-color: #2d3848;

$gray: #999;
$light-gray: #f7f7f7;
$white: #fff;

$small-screen: 576px;
$medium-screen: 768px;
$large-screen: 992px;
$xlarge-screen: 1200px;
