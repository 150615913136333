body {
  background: $light-gray;
}

a {
  color: $primary-color;
  &:hover {
    color: darken($primary-color, 10%);
  }
}

.dashboard-content {
  min-height: calc(100vh - 66px - 3rem);
  padding: 0 1.575rem;
}

.primary-color {
  background-color: $primary-color !important;
}
.primary-color-text {
  color: $primary-color !important;
}

.secondary-color {
  background-color: $secondary-color !important;
}
.secondary-color-text {
  color: $secondary-color !important;
}

.tooltip[x-placement^="top"],
.show[x-placement^="top"]:not(.tooltip) {
  z-index: 20;
}
