.text-primary {
  color: $primary-color !important;
}
.text-secondary {
  color: $secondary-color !important;
}

.line-height-collapsed {
  line-height: 1;
}

//----------------------
// Links
//----------------------
.side-nav.side-nav-light .collapsible li .collapsible-header.active {
  color: $secondary-color;
  font-weight: 500;
}
.side-nav.side-nav-light .collapsible-body a.active {
  font-weight: 500;
}

.nav-item a {
  color: $secondary-color;
  &:hover {
    color: lighten($secondary-color, 20%);
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: $primary-color;
}

.pagination .page-item.active .page-link {
  background-color: $secondary-color;
  &:hover {
    background-color: lighten($secondary-color, 20%);
  }
}

.admin-links {
  margin-top: 1rem;
}
