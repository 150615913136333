//----------------------
// Buttons
//----------------------

// basics
.btn-primary {
  background-color: $primary-color !important;
  &:hover {
    background-color: darken($primary-color, 5%) !important;
  }
}
.btn-outline-primary {
  color: $primary-color !important;
  border: 2px solid $primary-color !important;
  &:hover,
  &:focus,
  &:active,
  &:active:focus,
  .active {
    color: darken($primary-color, 5%) !important;
    border: 2px solid darken($primary-color, 5%) !important;
  }
}
.btn-outline-primary:not([disabled]):not(.disabled):active,
.btn-outline-primary:not([disabled]):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  border-color: darken($primary-color, 5%) !important;
}
.btn-secondary {
  background-color: $secondary-color !important;
  &:hover {
    background-color: darken($secondary-color, 5%) !important;
  }
}
.btn-outline-secondary {
  color: $secondary-color !important;
  border: 2px solid $secondary-color !important;
  &:hover,
  &:focus,
  &:active,
  &:active:focus,
  .active {
    color: darken($secondary-color, 5%) !important;
    border: 2px solid darken($secondary-color, 5%) !important;
  }
}
.btn-outline-secondary:not([disabled]):not(.disabled):active,
.btn-outline-secondary:not([disabled]):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  border-color: darken($secondary-color, 5%) !important;
}
.btn-outline-primary,
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary:active:focus,
.btn-outline-primary.active,
.btn-outline-secondary,
.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active,
.btn-outline-danger,
.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active,
.btn-outline-danger:active:focus,
.btn-outline-danger.active {
  background-color: $white !important;
}

//dropdowns
.dropdown .dropdown-menu.dropdown-primary .dropdown-item:hover,
.dropdown .dropdown-menu.dropdown-primary .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-primary .dropdown-item.active,
.dropup .dropdown-menu.dropdown-primary .dropdown-item:hover,
.dropup .dropdown-menu.dropdown-primary .dropdown-item:active,
.dropup .dropdown-menu.dropdown-primary .dropdown-item.active,
.dropleft .dropdown-menu.dropdown-primary .dropdown-item:hover,
.dropleft .dropdown-menu.dropdown-primary .dropdown-item:active,
.dropleft .dropdown-menu.dropdown-primary .dropdown-item.active,
.dropright .dropdown-menu.dropdown-primary .dropdown-item:hover,
.dropright .dropdown-menu.dropdown-primary .dropdown-item:active,
.dropright .dropdown-menu.dropdown-primary .dropdown-item.active {
  background-color: $primary-color !important;
}
.dropdown .dropdown-menu.dropdown-secondary .dropdown-item:hover,
.dropdown .dropdown-menu.dropdown-secondary .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-secondary .dropdown-item.active,
.dropup .dropdown-menu.dropdown-secondary .dropdown-item:hover,
.dropup .dropdown-menu.dropdown-secondary .dropdown-item:active,
.dropup .dropdown-menu.dropdown-secondary .dropdown-item.active,
.dropleft .dropdown-menu.dropdown-secondary .dropdown-item:hover,
.dropleft .dropdown-menu.dropdown-secondary .dropdown-item:active,
.dropleft .dropdown-menu.dropdown-secondary .dropdown-item.active,
.dropright .dropdown-menu.dropdown-secondary .dropdown-item:hover,
.dropright .dropdown-menu.dropdown-secondary .dropdown-item:active,
.dropright .dropdown-menu.dropdown-secondary .dropdown-item.active {
  background-color: $secondary-color !important;
}

// switches
.availability-switch {
  label input[type="checkbox"]:checked + .lever {
    background-color: rgba(0, 0, 0, 0.06);
    &:after {
      background-color: $secondary-color;
    }
  }
  label .lever {
    margin-left: 0.625rem;
  }
}

// small floating buttons
.btn-floating.btn-sm {
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 0 0 0 0.25rem;
  box-shadow: none;

  .fa {
    line-height: 25px;
    font-size: 0.6rem;
  }
}
