//----------------------
// Buttons
//----------------------

// basics
.border-primary {
  border-color: $primary-color !important;
}
.border-secondary {
  border-color: $secondary-color !important;
}