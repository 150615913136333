//----------------------
// Breadcrumbs
//----------------------
.breadcrumb-item a {
  color: $secondary-color;
  font-weight: 400;
  &:hover {
    color: lighten($secondary-color, 20%);
  }
}
