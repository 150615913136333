@import "./base/variables";

//----------------------
// ADMIN
//----------------------
.admin-header {
  padding: 0.5rem;
  .nav-item a {
    color: $white;
    color: $white;
    padding: 0.25rem 1.15rem;
    font-size: 0.75rem;

    &.active {
      background-color: darken($secondary-color, 5%);
    }
  }
}

.dropdown .dropdown-menu div.dropdown-item {
  padding: 0;
}

.link {
  color: $primary-color;
  &:hover {
    cursor: pointer;
  }
}
