.card-header {
  position: relative;

  &.justify-content-between.align-items-center
    .nav.order-2:first-child:last-child {
    width: 100%;
    justify-content: flex-end;
  }
}

.card-panel {
  box-shadow: none;
  .card-body {
    padding: 0.75rem;
  }
  .card-title {
    font-size: 1.15rem;
  }
}

.card-header-pills {
  margin-left: 0;
  margin-right: 0;
}

// contract budget cards
.contract-budget-card {
  &.no-amount {
    background: rgba(0, 0, 0, 0.05);
  }
  .card-body {
    padding: 1rem;
  }
}
