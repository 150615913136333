//----------------------
// Forms
//----------------------
.form {
  .md-form.md-outline {
    margin-top: 0;
  }
  .currency-selection .md-form.md-outline,
  .currency-selection .select-wrapper input.select-dropdown {
    margin-bottom: 0;
  }
}

.select-wrapper.md-form.md-outline .dropdown-content {
  max-height: 250px;
  overflow: auto;
}

.form .dropdown-content li:not(.disabled) > a,
.form .dropdown-content li:not(.disabled) > span {
  color: $primary-color;
}
.dropdown-primary .dropdown-content li.active,
.dropdown-primary .dropdown-content li a,
.dropdown-primary .dropdown-content li span:hover {
  background-color: $primary-color !important;
}
.select-wrapper.md-form.md-outline input.select-dropdown:focus,
.md-form.md-outline input[type="text"]:focus:not([readonly]),
.md-form.md-outline input[type="password"]:focus:not([readonly]),
.md-form.md-outline input[type="email"]:focus:not([readonly]),
.md-form.md-outline input[type="url"]:focus:not([readonly]),
.md-form.md-outline input[type="time"]:focus:not([readonly]),
.md-form.md-outline input[type="date"]:focus:not([readonly]),
.md-form.md-outline input[type="datetime-local"]:focus:not([readonly]),
.md-form.md-outline input[type="tel"]:focus:not([readonly]),
.md-form.md-outline input[type="number"]:focus:not([readonly]),
.md-form.md-outline input[type="search-md"]:focus:not([readonly]),
.md-form.md-outline input[type="search"]:focus:not([readonly]),
.md-form.md-outline textarea.md-textarea:focus:not([readonly]) {
  border-color: $primary-color;
  box-shadow: inset 0 0 0 1px $primary-color;
}
.md-form.md-outline input[type="text"]:focus:not([readonly]) + label,
.md-form.md-outline input[type="password"]:focus:not([readonly]) + label,
.md-form.md-outline input[type="email"]:focus:not([readonly]) + label,
.md-form.md-outline input[type="url"]:focus:not([readonly]) + label,
.md-form.md-outline input[type="time"]:focus:not([readonly]) + label,
.md-form.md-outline input[type="date"]:focus:not([readonly]) + label,
.md-form.md-outline input[type="datetime-local"]:focus:not([readonly]) + label,
.md-form.md-outline input[type="tel"]:focus:not([readonly]) + label,
.md-form.md-outline input[type="number"]:focus:not([readonly]) + label,
.md-form.md-outline input[type="search-md"]:focus:not([readonly]) + label,
.md-form.md-outline input[type="search"]:focus:not([readonly]) + label,
.md-form.md-outline textarea.md-textarea:focus:not([readonly]) + label {
  color: $primary-color;
}
.select-wrapper.md-form.md-outline input.select-dropdown:disabled {
  background: $light-gray;
}
.select-wrapper.disabled.md-form.md-outline label {
  z-index: 2;
  color: $gray;
}
.select-wrapper.disabled.md-form.md-outline .caret {
  z-index: 3;
  color: $gray;
}
.input-warning {
  position: absolute;
  bottom: -2rem;
  font-size: 0.75rem;
}

.dropdown-content .md-form {
  margin-top: 0;
  margin-bottom: 0.5rem;
  &.md-outline input[type="text"]:focus:not([readonly]),
  &.md-outline input[type="text"]:focus:not([readonly]) + label {
    margin-bottom: 0;
  }
}

.md-form input:not([type]):focus:not([readonly]),
.md-form input[type="text"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="password"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="email"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="url"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="time"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="date"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]),
.md-form
  input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="tel"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="number"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="search"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="phone"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="search-md"]:focus:not([readonly]),
.md-form textarea.md-textarea:focus:not([readonly]),
.select-wrapper:not(.md-outline) .select-dropdown:focus {
  border-bottom: 1px solid $primary-color;
  box-shadow: 0 1px 0 0 $primary-color;
}

.form .input-group .md-form.md-outline {
  margin-top: 0;
  margin-bottom: 0;
  flex-grow: 1;

  &.select-wrapper input.select-dropdown {
    margin-bottom: 0;
    border-radius: 0;
    box-shadow: none;
  }
}

.input-group > .input-group-append > .btn {
  margin: 0;
}

.picker {
  z-index: 0;
  width: 100%;

  .MuiInput-underline-9:hover:not(.MuiInput-disabled-8):not(.MuiInput-focused-7):not(.MuiInput-error-10):before {
    border-bottom: 2px solid $primary-color;
  }

  .MuiInput-underline-9:after {
    border-color: $primary-color;
  }
}

.form .form-check-input[type="checkbox"]:checked + label:before,
.form label.btn input[type="checkbox"]:checked + label:before {
  border-color: transparent $primary-color $primary-color transparent;
}

.required-label {
  & > label:after {
    margin: -0.2em 0 0 0.2em;
    content: "*" !important;
    color: #f88b5e;
    opacity: 1 !important;
    position: static !important;
    top: 0;
    display: inline !important;
  }
}

//ERRORS
.input-error.md-form.md-outline .select-dropdown,
.md-form.md-outline .input-error {
  border-color: #ff3547 !important;
}

// SUCCESS
.md-form input:not([type]).valid,
.md-form input:not([type]):focus.valid,
.md-form input[type="text"]:not(.browser-default).valid,
.md-form input[type="text"]:not(.browser-default):focus.valid,
.md-form input[type="password"]:not(.browser-default).valid,
.md-form input[type="password"]:not(.browser-default):focus.valid,
.md-form input[type="email"]:not(.browser-default).valid,
.md-form input[type="email"]:not(.browser-default):focus.valid,
.md-form input[type="url"]:not(.browser-default).valid,
.md-form input[type="url"]:not(.browser-default):focus.valid,
.md-form input[type="time"]:not(.browser-default).valid,
.md-form input[type="time"]:not(.browser-default):focus.valid,
.md-form input[type="date"]:not(.browser-default).valid,
.md-form input[type="date"]:not(.browser-default):focus.valid,
.md-form input[type="datetime"]:not(.browser-default).valid,
.md-form input[type="datetime"]:not(.browser-default):focus.valid,
.md-form input[type="datetime-local"]:not(.browser-default).valid,
.md-form input[type="datetime-local"]:not(.browser-default):focus.valid,
.md-form input[type="tel"]:not(.browser-default).valid,
.md-form input[type="tel"]:not(.browser-default):focus.valid,
.md-form input[type="number"]:not(.browser-default).valid,
.md-form input[type="number"]:not(.browser-default):focus.valid,
.md-form input[type="search"]:not(.browser-default).valid,
.md-form input[type="search"]:not(.browser-default):focus.valid,
.md-form input[type="phone"]:not(.browser-default).valid,
.md-form input[type="phone"]:not(.browser-default):focus.valid,
.md-form input[type="search-md"].valid,
.md-form input[type="search-md"]:focus.valid,
.md-form textarea.md-textarea.valid,
.md-form textarea.md-textarea:focus.valid {
  border-bottom: 1px solid $primary-color;
  box-shadow: 0 1px 0 0 $primary-color;
}
